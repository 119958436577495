import type { NextRequest } from "@/modules/fetch/types"
import type { I18nLocale } from "@/modules/i18n/types"
import type { CurrentUser, CurrentUserResponse } from "@/modules/user/api/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleSchemaValidation } from "@/modules/apiRequests/schemaValidation"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { toCamelCaseKeys } from "@/modules/format/object/toCamelCaseKeys"
import { currentUserSchema } from "@/modules/user/api/schemas"

import { CURRENT_USER_ENDPOINT, CURRENT_USER_QUERY_KEY } from "./constants"

type GetCurrentUserParams = {
  hostname: string
  locale: I18nLocale
  req?: NextRequest
}

export const getCurrentUser = async (params: GetCurrentUserParams): Promise<CurrentUser> => {
  const { hostname, locale, req } = params

  return fetcher(CURRENT_USER_ENDPOINT, {
    hostname,
    nextRequest: req,
    requestInit: {
      headers: {
        "Accept-Language": locale,
      },
    },
    serviceDomain: "MONOLITH_API",
    storageKey: `${CURRENT_USER_QUERY_KEY}::${locale}`,
  })
    .then(getResultFromResponse<CurrentUserResponse>({ transformResult: toCamelCaseKeys<CurrentUserResponse> }))
    .then(({ data }) => ({
      ...data,
      isLogged: data.id !== "0",
    }))
    .then(data => handleSchemaValidation(data, currentUserSchema, CURRENT_USER_ENDPOINT))
    .catch(handleFetchError(CURRENT_USER_ENDPOINT))
}
